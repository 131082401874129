import React from 'react';
import SEO from '~/components/seo';
import { PageProps } from 'gatsby';
import { HouseholdBonusQuestions } from '~/components/HouseholdBonusQuestions';

const HouseholdBonusQuestionsPage: React.FC<PageProps> = props => (
  <>
    <SEO
      pageContext={props.pageContext}
      title="WellForm.bonusQuestions.title"
    />
    <HouseholdBonusQuestions {...props} />
  </>
);

export default HouseholdBonusQuestionsPage;
