import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '~/state/hooks/useNavigate';
import { WellSubmissionIds } from '~/context/global/reports/types';
import { HouseholdMemberRadioButtons } from './HouseholdMemberRadioButtons';
import { Divider, useMediaQuery, useTheme } from '@material-ui/core';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useBonusQuestions } from '../forms/Well/BonusQuestion/useBonusQuestions';
import { useGlobalState } from '~/context/global';
import { submitWellReportBonusQuestions } from '~/requests/reports';
import { useSnackbar } from '~/state/hooks/useSnackbar';
import * as BonusQuestionStyles from '../forms/Well/BonusQuestion/styles';

interface Props {
  location?: Location & {
    state: {
      wellSubmissionIds?: WellSubmissionIds;
    };
  };
}

export const HouseholdBonusQuestions: React.FC<Props> = ({ location }) => {
  const [selectedOptions, setSelectedOptions] = useState<{
    [id: string]: string;
  }>({});
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useSnackbar();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { navigate } = useNavigate();
  const bonusQuestionsHook = useBonusQuestions();
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const translationPrefix = 'WellForm.bonusQuestions';
  const {
    householdMemberSubmissionIds,
    userSubmissionId,
  } = location?.state?.wellSubmissionIds;
  const { account } = useGlobalState().user;

  // If there is no account information in state, redirect to the home page.
  if (!Object.keys(account).length) {
    navigate('', { replace: true });
  }

  useEffect(() => {
    if (householdMemberSubmissionIds) {
      const initialSelectedOptions = householdMemberSubmissionIds.reduce(
        (acc, item) => ({ ...acc, [`${item.householdMemberId}`]: '' }),
        { user: '' }
      );
      setSelectedOptions(initialSelectedOptions);
    }
  }, [householdMemberSubmissionIds]);

  const hasAnyValue = Object.values(selectedOptions).some(value =>
    Boolean(value)
  );

  const hasYesValue = Object.values(selectedOptions).some(value =>
    Boolean(value === 'yes')
  );

  const submitAllNoResponses = async () => {
    try {
      setIsLoading(true);
      Object.entries(selectedOptions).forEach(async ([key, value]) => {
        if (value !== 'no') {
          return;
        }
        const submissionId =
          key === 'user'
            ? userSubmissionId
            : householdMemberSubmissionIds.find(
                item => item.householdMemberId === parseInt(key)
              )?.submissionId;
        const recaptchaToken = await executeRecaptcha('well');
        const bonusQuestionsRequestBody = bonusQuestionsHook.prepareFormValuesForRequest(
          submissionId
        );
        await submitWellReportBonusQuestions(
          bonusQuestionsRequestBody,
          recaptchaToken
        );
      });
    } catch (err) {
      showSnackbar(t('ErrorHandling.submissionErrors.ServerError'), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const navigateToBonusQuestions = () => {
    const householdSubmissionIds = [];
    if (selectedOptions.user === 'yes') {
      householdSubmissionIds.push({
        name: 'you',
        submissionId: location?.state?.wellSubmissionIds.userSubmissionId,
      });
    }
    householdMemberSubmissionIds.forEach(item => {
      if (selectedOptions[item.householdMemberId] === 'yes') {
        householdSubmissionIds.push({
          name: account?.householdMembers?.find(
            member => member.id === item.householdMemberId
          ).name,
          submissionId: item.submissionId,
        });
      }
    });
    navigate('bonus-questions', {
      state: {
        householdSubmissionIds,
      },
    });
  };

  const submitForm = async () => {
    if (!executeRecaptcha) {
      return showSnackbar(t('ErrorHandling.recaptcha'), 'error');
    }
    submitAllNoResponses();
    if (hasYesValue) {
      navigateToBonusQuestions();
      return;
    }
    bonusQuestionsHook.goToThankYouPage();
  };

  const handleOptionChange = (id: string, value: string) => {
    setSelectedOptions(prev => ({ ...prev, [id]: value }));
  };

  return (
    <BonusQuestionStyles.PageContainer>
      <BonusQuestionStyles.BonusQuestionsHeader>
        <BonusQuestionStyles.BonusQuestionsTitle>
          {t(`${translationPrefix}.title`)}
        </BonusQuestionStyles.BonusQuestionsTitle>
        <BonusQuestionStyles.BonusQuestionsSubtitle>
          {t(`${translationPrefix}.subtitle`)}
        </BonusQuestionStyles.BonusQuestionsSubtitle>
      </BonusQuestionStyles.BonusQuestionsHeader>
      <Divider />
      <BonusQuestionStyles.BonusQuestionsSubtitle
        style={{ paddingTop: '2rem' }}
      >
        {t(`${translationPrefix}.householdSubtitle`)}
      </BonusQuestionStyles.BonusQuestionsSubtitle>
      {userSubmissionId && (
        <HouseholdMemberRadioButtons
          label={t(
            `${translationPrefix}.householdMemberRadioButtons.userLabel`
          )}
          value={selectedOptions.user || ''}
          onChange={handleOptionChange}
          isMobile={isMobile}
          name="yourself"
          ariaLabel="yourself"
          memberId="user"
        />
      )}
      {householdMemberSubmissionIds.map((item, index) => {
        const householdMemberName = account?.householdMembers?.find(
          member => member.id === item.householdMemberId
        ).name;
        return (
          <HouseholdMemberRadioButtons
            key={item.householdMemberId}
            label={householdMemberName}
            value={selectedOptions[item.householdMemberId] || ''}
            onChange={handleOptionChange}
            isMobile={isMobile}
            name={`householdMember${index + 1}`}
            ariaLabel={`householdMember${index + 1}`}
            memberId={`${item.householdMemberId}`}
          />
        );
      })}
      <BonusQuestionStyles.BonusQuestionsActionsContainer>
        <BonusQuestionStyles.BonusQuestionsSubmitButton
          data-cy="submit-bonus-questions-button"
          onClick={submitForm}
          disabled={isLoading || !hasAnyValue}
        >
          {hasYesValue
            ? t(`${translationPrefix}.nextButton`)
            : t(`${translationPrefix}.submitButton`)}
        </BonusQuestionStyles.BonusQuestionsSubmitButton>
        <BonusQuestionStyles.BonusQuestionsSkipButton
          onClick={bonusQuestionsHook.goToThankYouPage}
          data-cy="skip-bonus-questions-button"
          disabled={isLoading}
        >
          {t(`${translationPrefix}.skipButton`)}
        </BonusQuestionStyles.BonusQuestionsSkipButton>
      </BonusQuestionStyles.BonusQuestionsActionsContainer>
    </BonusQuestionStyles.PageContainer>
  );
};
