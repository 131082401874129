import React from 'react';
import { Radio, RadioGroup } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as BonusQuestionStyles from '../forms/Well/BonusQuestion/styles';

interface Props {
  label: string;
  value: string;
  onChange: (memberId: string, answer: string) => void;
  isMobile: boolean;
  name: string;
  ariaLabel: string;
  memberId: string;
}

export const HouseholdMemberRadioButtons: React.FC<Props> = ({
  label,
  value,
  onChange,
  isMobile,
  name,
  ariaLabel,
  memberId,
}) => {
  const { t } = useTranslation();
  const translationPrefix =
    'WellForm.bonusQuestions.householdMemberRadioButtons.answerLabels';

  return (
    <BonusQuestionStyles.FormSection>
      <BonusQuestionStyles.Question>{label}</BonusQuestionStyles.Question>
      <BonusQuestionStyles.RadioFormControl>
        <RadioGroup
          value={value}
          row={!isMobile}
          name={name}
          aria-label={ariaLabel}
          onChange={(_, answer) => onChange(memberId, answer)}
        >
          <BonusQuestionStyles.BaseInputLabel
            value="yes"
            control={<Radio color="primary" />}
            label={t(`${translationPrefix}.yes`)}
            data-cy="household-member-yes"
          />
          <BonusQuestionStyles.BaseInputLabel
            value="no"
            control={<Radio color="primary" />}
            label={t(`${translationPrefix}.no`)}
            data-cy="household-member-no"
          />
        </RadioGroup>
      </BonusQuestionStyles.RadioFormControl>
    </BonusQuestionStyles.FormSection>
  );
};
