import styled from 'styled-components';
import breakpoint from '~/common/breakpoint';
import {
  Grid,
  Button,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';

export const PageContainer = styled(Grid).attrs({
  container: true,
  direction: 'column',
})`
  padding: 4em 2em;

  ${breakpoint.md`
    width: 50%;
    margin: auto;
    padding: 5em 0;
  `}
`;

export const BonusQuestionsHeader = styled.div`
  padding: 1rem;
  padding-bottom: 33px;
`;

export const BaseTypography = styled(Typography).attrs({
  align: 'center',
})`
  font-size: 16px;
  ${breakpoint.md`
    font-size: 20px;
  `}
`;

export const BonusQuestionsTitle = styled(BaseTypography)`
  font-weight: bold;
  line-height: 32px;
`;

export const BonusQuestionsSubtitle = styled(BaseTypography)`
  line-height: 32px;
`;

export const Question = styled(BaseTypography)`
  line-height: 22px;
  font-weight: bold;

  ${breakpoint.md`
    font-size: 18px;
    line-height: 22px;
    font-weight: normal;
  `}
`;

export const BaseInputLabel = styled(FormControlLabel)`
  color: ${props => props.theme.palette.text.secondary};

  && {
    .MuiTypography-body1 {
      font-size: 1rem;
      line-height: 20px;
    }
  }
`;

export const TextArea = styled(TextField).attrs({
  rows: 3,
  multiline: true,
  fullWidth: true,
  variant: 'outlined',
})`
  textarea {
    font-size: 16px;
    line-height: 20px;
    color: ${props => props.theme.palette.text.secondary};
  }
`;

export const FormSection = styled.section`
  padding: 2rem 0;
`;

export const RadioFormControl = styled(FormControl).attrs({
  fullWidth: true,
  component: 'fieldset',
})`
  ${breakpoint.md`
    align-items: center;
  `}
`;

export const BonusQuestionsActionsContainer = styled(Grid).attrs({
  container: true,
  direction: 'column',
})`
  padding-top: 2rem;

  button:not(:first-child) {
    margin-top: 16px;
  }

  ${breakpoint.sm`
    justify-content: center;
    align-items: center;
  `}
`;

export const BonusQuestionsSubmitButton = styled(Button).attrs({
  color: 'primary',
  variant: 'contained',
})`
  height: 36px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: capitalize;

  ${breakpoint.sm`
    width: 320px;
  `}
`;

export const BonusQuestionsSkipButton: typeof Button = styled(
  BonusQuestionsSubmitButton
).attrs({
  variant: 'outlined',
})`
  ${breakpoint.sm`
    width: 320px;
  `}
`;
