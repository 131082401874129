import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIndexSet } from '~/state/hooks/useIndexSet';
import { WellReportBonusQuestionsRequestBody } from '~/requests/reports';
import { TestsPerformed, TestResult } from '~/context/global/reports/types';
import { MedicalTestFollowUpOption } from './MedicalTestFollowUp';
import { useNavigate } from '~/state/hooks/useNavigate';

interface UseBonusQuestions {
  hasTestedRecently?: 'yes' | 'no' | '';
  typesOfTestPerformed: TestsPerformed;
  medicalTestFollowUp: MedicalTestFollowUpOption;
  otherTypeText: string;
  reasonsForTesting: Set<number>;
  otherReasonText: string;

  setHasTestedRecently: (value: 'yes' | 'no') => void;
  setTypesOfTest: (tests: TestsPerformed) => void;
  setMedicalTestFollowUp: (option: MedicalTestFollowUpOption) => void;
  setOtherTypeText: (text: string) => void;
  selectReasonForTesting: (optionIndex: number) => void;
  setOtherReasonText: (text: string) => void;
  addTestResult: (testType: string, result: TestResult) => void;
  resetForm: () => void;
  goToThankYouPage: () => void;

  prepareFormValuesForRequest: (
    submissionId?: number
  ) => WellReportBonusQuestionsRequestBody;
}

export const useBonusQuestions = (): UseBonusQuestions => {
  const [hasTestedRecently, setHasTestedRecently] = useState<'yes' | 'no' | ''>(
    ''
  );
  const [typesOfTestPerformed, setTypesOfTest] = useState<TestsPerformed>({});
  const [medicalTestFollowUp, setMedicalTestFollowUp] = useState<
    MedicalTestFollowUpOption
  >();
  const [otherTypeText, setOtherTypeText] = useState<string>();
  const [
    reasonsForTesting,
    selectReasonForTesting,
    resetReasonsForTesting,
  ] = useIndexSet();
  const [otherReasonText, setOtherReasonText] = useState<string>();

  const { t } = useTranslation();
  const translationPrefix = 'WellForm.bonusQuestions.reasonsForTesting';
  const reasonsForTestingOptions: string[] = t(`${translationPrefix}.answers`, {
    returnObjects: true,
  });
  const { navigate } = useNavigate();

  const prepareFormValuesForRequest = (
    submissionId: number
  ): WellReportBonusQuestionsRequestBody => {
    const values = {
      submissionId,
      medicalTestFollowUp,
      testedLastSevenDays: hasTestedRecently === 'yes',
      typesOfTestPerformed: getTypesOfTestsPerformed(),
      reasonsForTesting: reasonsForTestingOptions.filter((_, i) =>
        reasonsForTesting.has(i)
      ),
    };
    concatOtherReason(values.reasonsForTesting);
    return values;
  };

  const resetForm = () => {
    setHasTestedRecently('');
    setTypesOfTest({});
    setMedicalTestFollowUp(undefined);
    setOtherTypeText(undefined);
    resetReasonsForTesting();
    setOtherReasonText(undefined);
  };

  // If the "OTHER" test option is selected, add the user's "additional comments" to the end of that key.
  const getTypesOfTestsPerformed = (): TestsPerformed => {
    const typesOfTests = { ...typesOfTestPerformed };
    if (Object.keys(typesOfTests).includes('OTHER') && otherTypeText) {
      typesOfTests['OTHER - ' + otherTypeText] = '';
      delete typesOfTests['OTHER'];
    }
    return typesOfTests;
  };

  const concatOtherReason = (selectedReasonsForTesting: string[]) => {
    const availableOptionsLastIdx = reasonsForTestingOptions.length - 1;
    const userSelectedOtherReason = reasonsForTesting.has(
      availableOptionsLastIdx
    );
    if (userSelectedOtherReason && otherReasonText) {
      const otherReasonIdx = selectedReasonsForTesting.length - 1;
      selectedReasonsForTesting[otherReasonIdx] += ` - ${otherReasonText}`;
    }
  };

  const addTestResult = (testType: string, result: TestResult) => {
    const updatedTestsPerformed = { ...typesOfTestPerformed };
    updatedTestsPerformed[testType] = result;
    setTypesOfTest(updatedTestsPerformed);
  };

  const goToThankYouPage = () => {
    navigate('thank-you', {
      replace: true,
      state: { isWellForm: true, bonusQuestionsAsked: true },
    });
  };

  return {
    hasTestedRecently,
    typesOfTestPerformed,
    medicalTestFollowUp,
    otherTypeText,
    reasonsForTesting,
    otherReasonText,
    goToThankYouPage,
    setHasTestedRecently,
    setTypesOfTest,
    setMedicalTestFollowUp,
    setOtherTypeText,
    selectReasonForTesting,
    setOtherReasonText,
    addTestResult,
    prepareFormValuesForRequest,
    resetForm,
  };
};
