import { useState } from 'react';

type UseIndexSet = [Set<number>, (idx: number) => void, () => void];

export const useIndexSet = (): UseIndexSet => {
  const [data, set] = useState(new Set<number>());

  const setData = (idx: number) => {
    data.has(idx) ? data.delete(idx) : data.add(idx);
    set(new Set(data));
  };

  const resetSet = () => {
    set(new Set());
  };

  return [data, setData, resetSet];
};

type UseStringSet = [Set<string>, (value: string) => void];

export const useStringSet = (): UseStringSet => {
  const [data, set] = useState(new Set<string>());

  const setData = (value: string) => {
    data.has(value) ? data.delete(value) : data.add(value);
    set(new Set(data));
  };

  return [data, setData];
};
